import React from "react"
import { Link } from "gatsby"
import Footer from "../components/footer"


import logoGold from "../img/gh-rough.jpg"

export default function thankyou() {
    return (
      <div className="thank-you background--mid">
        <script
        dangerouslySetInnerHTML={{
          __html: `
                  window.addEventListener('load',function(){
                  if(document.getElementsByClassName('get-started-form--submit').length>0){
                  document.getElementsByClassName('get-started-form--submit')[0].addEventListener('click',function(){
                    var myVar = setInterval(function(){ var x = 0;  if(x==0){
                        if(document.getElementsByClassName('thank-you').length>0){
                        gtag('event', 'conversion', {'send_to': 'AW-630826683/T_ARCNKS3f0CELvN5qwC'});
                        clearInterval(myVar); x=1;
                        }  }  }, 1000);
                  })
                  }
                  })
              `,
        }}
      />

            <div className="wrapper thank-you--content">
                <div className="row">
                    <div className="col-12">
                     <h1>Thank you</h1>
                     <p>Your submission has been sent. We will get back to you shortly.</p>
                     <Link to="/" className="dark-link"><span className="back-arrow">→</span>Return Home</Link>
                    </div>
                </div>
            </div>
            <section className="footer background--dark" name="contact" id="contact">
                <Footer></Footer>
                <div className="container footer--bottom">
                    <div className="wrapper--outer">
                    <img className="logo-small" src={logoGold} alt="gypsy hub gold logo" width="150" height="150" />
                    </div>
                </div>
            </section>
        </div>
        );
    }
